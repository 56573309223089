.page-header {
	text-align: left;
	margin-top: var(--section-small);
	margin-bottom: var(--section-small);

	&__subtitle {
		font-size: var(--body-font-size);
	}

	&__title {
		display: block;
        line-height: 1;
		font-size: var(--giga-font-size);
		margin-bottom: 0;
	}
}
