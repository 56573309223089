.inner-scaffold {
    display: grid;
    gap: 4.8888888vw;

    @include screen($bp768) {
        gap: 5.11111vw;
    }

    @include screen($bp992) {
        grid-template-columns: 2fr 1fr;
    }

    @include screen($bp1600) {
        gap: 80px;
    }

    // .mainbar {
    //     @include screen($bp992) {
    //         grid-column: 1;
    //         grid-row: 1;
    //     }
    // }

    // .sidebar {
    //     @include screen($bp992) {
    //         grid-column: 2;
    //         grid-row: 1;
    //     }
    // }
}
