// small
$bp360:     22.5em;
$bp480:     30em;

$bp559:     34.9375em;
$bp560:     35em;

// small
$bp767:     47.9375em;
$bp768:     48em;

// medium
$bp991:     61.9375em;
$bp992:     62em;

// large
$bp1199:     74.9375em;
$bp1200:     75em;
$bp1400:     87.5em;

// extra large
$bp1600:     100em;
$bp1800:     112.5em;
$bp1920:     120em;
$bp2400:     150em;

// mobile menu related variables
$bpmobile:     $bp991;
$bpdesktop:    $bp992;


/*
Variable Example
*/
@media screen and (min-width: $bp360) {
}


/*
Breakpoint Mixins
*/
@mixin screen($breakpoint, $direction: 'min') {
    @media screen and (#{$direction}-width: $breakpoint) {
        @content;
    }
}
