

// Make sure you match the breakpoints in the js
.tab-exp-nav {
	@include screen($bpdesktop) {
		&__list {
			&__btn {
				background-color: $color-grey-01;
				border: 2px solid $color-grey-02;
				display: inline-grid;
				justify-content: center;
				align-items: center;
				line-height: 1.125;
				min-height: $spacing*6;
				padding: $spacing $spacing*2;

				&[aria-selected="true"] {
					background-color: $color-secondary;
					color: $color-white;
				}
			}
		}
	}

	&__panel {
		position: relative;
		padding-top: $spacing*2;

		&__contents {
			@include screen($bpmobile, 'max') {
				overflow: hidden;
				transition: height $link-transition;
			}

			&.tabpanel--closed {
				height: 10ch !important;
				display: -webkit-box;
				  -webkit-line-clamp: 1;
				  -webkit-box-orient: vertical;
			}

			&__heading {
				@include h4;
				margin-top: 0;
				margin-bottom: 0;

				@include screen($bpdesktop) {
					display: none;
				}

			}
		}

		&__more-btn {
			background-color: $color-secondary;
			color: $color-white;
			display: grid;
			justify-content: center;
			align-items: center;
			@include button-typography;
			cursor: pointer;
			max-width: 200px;
			margin: $spacing 0 0 0;
			height: 40px;
			opacity: 1;
			overflow: hidden;
			transition: all $link-transition;
			clip: unset;

			&--hide {
				font-size: 0;
				height: 0px;
				border: 0px;
				padding-top: 0;
				padding-bottom: 0;
				clip: rect(0 0 0 0);
				margin: 0px;
				display: grid;
				opacity: 0;
			}

			@include screen($bpdesktop) {
				display: none;
			}

		}
	}
}
