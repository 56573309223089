
@include screen($bp768) {
    @supports (display: grid) {
        .media-article {
            display:grid;
            grid-gap:32px;
            grid-template-columns: 2fr 3fr;
        }
    }
}
