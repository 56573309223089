.entry-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    &.full-width {
        grid-template-columns: 1fr;
    }

    &.large {
        @include screen($bp768) {
            grid-template-columns:repeat(auto-fill, minmax(320px, 1fr));
        }

        @include screen($bp992) {
            grid-template-columns:repeat(auto-fill, minmax(600px, 1fr));
        }
    }

    &.medium {
        @include screen($bp768) {
            grid-template-columns:repeat(auto-fill, minmax(320px, 1fr));
        }

        @include screen($bp992) {
            grid-template-columns:repeat(auto-fill, minmax(400px, 1fr));
        }
    }

    &.small {
        @include screen($bp560) {
            grid-template-columns:repeat(auto-fill, minmax(320px, 1fr));
        }
    }

    &.extra-small {
        grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));
    }

    &.tiny {
        grid-template-columns:repeat(auto-fill, minmax(160px, 1fr));
    }
}
