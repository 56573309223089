.content-block {
    display: grid;
    gap: 4.8888888vw;

    @include screen($bp768) {
        gap: 5.11111vw;
    }

    @include screen($bp992) {
        grid-template-columns: 1fr;
    }

    @include screen($bp1600) {
        column-gap: 80px;
        row-gap: 40px;
    }

    &.full-width-media {

    }

    &.half-media-right {
        @include screen($bp992) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.half-media-left {
         @include screen($bp992) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.third-media-right {
        @include screen($bp992) {
            grid-template-columns: 2fr 1fr;
        }

    }

    &.third-media-left {
        @include screen($bp992) {
            grid-template-columns: 1fr 2fr;
        }
    }

    &.third-copy-right {
        @include screen($bp992) {
            grid-template-columns: 2fr 1fr;
        }

    }

    &.third-copy-left {
        @include screen($bp992) {
            grid-template-columns: 1fr 2fr;
        }
    }
}
