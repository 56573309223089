
.hero-banner {

    display: flex;
    flex-flow: column nowrap;

    &__img {
        flex: 1 0 auto;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }


    }

    &__text {
        background-color: $color-secondary;
        color: $color-white;
        padding: $spacing--xlarge;
        padding-left:  var(--default-container-gutter);
        padding-right:  var(--default-container-gutter);

        @include screen($bp1200) {
            @include box;
        }
        @include screen($bp1400) {
            padding-left: var(--default-container-gutter);
        }


        &__title {
            color: $color-white;
        }
    }
}
