.image-gallery__figure {
    display: block;
    overflow: hidden;
    margin: 0;
}

.image-gallery__slider {
    .splide__slide {
        img {
            user-select: none;
            transition:
                opacity 1s,
                transform 1s;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.9;
                transform: scale3d(1.1,1.1,1);
            }
        }

        &.active img {
            animation: fadeIn 350ms ease-in;
	        animation-fill-mode: forwards;
        }
    }

    &.columns-2 .splide__slide {
        flex: 0 0 50%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &.columns-3 .splide__slide {
        flex: 0 0 33.333%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &.columns-4 .splide__slide {
        flex: 0 0 25%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &.columns-5 .splide__slide {
        flex: 0 0 20%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &.columns-6 .splide__slide {
        flex: 0 0 16.666%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }
}

.image-gallery__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    &.columns-2,
    &.columns-4 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.columns-3,
    &.columns-5,
    &.columns-6  {
        grid-template-columns: repeat(3, 1fr);
    }

    &.columns-4 {
        @include screen($bp768) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.columns-5 {
        @include screen($bp768) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &.columns-6 {
        @include screen($bp768) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    figure,
    img {
        width: 100%;
    }

    .image-gallery__figure  {
        position: relative;

        &:hover,
        &:focus,
        &:active {
            figcaption {
                opacity: 1;
            }
        }

        figcaption {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10px;
            bottom: 10px;
            right: 10px;
            left: 10px;
            padding: 10px;
            z-index: 2;
            opacity: 0;
            text-align: center;
            background-color: rgba($color-black, 50%);
            color: $color-white;
            margin: 0;
            transition: 0.4s all ease;
            line-height: 1.3;

            @include screen($bp560) {
                font-size: 18px;
                top: 20px;
                bottom: 20px;
                right: 20px;
                left: 20px;
                padding: 16.6666%;
            }

            @include screen($bp768) {
                font-size: 24px;
            }
        }
    }

}
