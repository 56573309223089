.inner-banner {
    min-height: 25vh;
    position: relative;
    display: grid;

    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba($color-black, 0.3);
        }
    }

    &__content {
        position: relative;
        @include reverse-text;
        display: flex;
        justify-content: start;
        align-items: center;
        height: 100%;

        .page-header__heading {
            margin-top: $spacing;
        }

        .breadcrumb {
            margin-top: $spacing*2;
            color: $color-white;

            a {
                color: rgba($color-white, 0.8);

                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }
}
