
.splide {

    &__arrows {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        z-index: 3;
    }

    &__arrow {
        position: absolute;
        background-color: rgba($color-black, 0);
        border: 0;
        cursor: pointer;
        padding-top: $spacing*2;
        padding-bottom: $spacing*2;
        transition: background $link-transition;
        display: grid;
        border-top-left-radius: $spacing*.5;
        border-bottom-left-radius: $spacing*.5;

        svg * {
            fill: $color-white;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($color-black, .75);
        }

        &--prev {
            left: 0;
            transform: rotate(180deg);
        }

        &--next {
            right: 0;
        }
    }

    &__pagination {
        gap: $spacing*2;

        &__page {
            cursor: pointer;
            background-color: $color-grey-03;
            border: 0;
            border-radius: 200px;
            width: $spacing*1.5;
            height: $spacing*1.5;
            transition: background $link-transition;

            &.is-active {
                background-color: $color-primary;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $color-grey-06
            }
        }
    }
}
