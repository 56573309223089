// CSS variables for spacing

// basic media object padding
:root {
    --sidepadding: 8px;
    --vertpadding: 8px;
}

@include screen($bp360) {
    :root {
        --sidepadding: #{$spacing*1.5};
        --vertpadding: #{$spacing*1.25};
    }
}

@include screen($bp560) {
    :root {
        --sidepadding: #{$spacing*2};
        --vertpadding: #{$spacing*1.5};
    }
}

@include screen($bp768) {
    :root {
        --sidepadding: #{$spacing*3};
        --vertpadding: #{$spacing*2};
    }
}

@include screen($bp1400) {
    :root {
        --sidepadding: #{$spacing*5.5};
        --vertpadding: #{$spacing*3.5};
    }
}
