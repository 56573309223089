.cta-box {
    background-color: $color-grey-01;
    @include box-with-grid-deco;

    &__heading {
        margin-top: 0;
        line-height: normal;

        span {
            display: block;
        }
    }

    &__subtitle {
        @include subtitle;
        font-size: var(--body-font-size);
    }

    &__title {
        @include h3;
    }

    &__cta {
        a {
            @include button;
        }
    }

    &__content {

        > *:last-child {
            margin-bottom: 0;
        }
    }
}
