/* ---- Slide Toggle ---- */
.slide-toggle__trigger {
	-webkit-appearance: none;
	display: block;
	width: 100%;
    position: relative;
	white-space: normal;
    vertical-align: middle;
	outline: none;
	border: none;
	transition: all $link-transition;
	background-color: $color-grey-01;
	background-color: transparent;
	padding: 8px 0;
	margin: 0;
	text-align: left;
	color: $color-grey-05;

	&:hover,
	&:focus {
		color: $color-grey-09;

		&:before,
		&:after {
			background-color: $color-grey-09;
		}
	}

	&:before,
	&:after {
		content: "\00a0";
		width: 12px;
		height: 3px;
		display: block;
		position: absolute;
		background-color: $color-grey-05;
		margin: 0 auto;
		right: 20px;
		top: 50%;
		transition: all 400ms ease;
	}

	&:before {
		transform: rotate(45deg) translateX(-5px);
	}

	&:after {
		transform: rotate(-45deg) translateX(5px);
	}

	&[aria-pressed="true"] {
		color: $color-secondary;

		&:before,
		&:after {
			background-color: $color-secondary;
			top: calc(50% - 2px);
		}

		&:hover,
		&:focus {
			color: $color-grey-09;

			&:before,
			&:after {
				background-color: $color-grey-09;
			}
		}

		&:before {
			transform: rotate(-45deg) translateX(-5px);
		}

		&:after {
			transform: rotate(45deg) translateX(5px);
		}
	}
}

.archive-links__list[aria-expanded="false"] {
	display: none;
}

.archive-links__list[aria-expanded="true"] {
	animation: fadeInDown 200ms ease;
	animation-fill-mode: forwards;
}

.archive-links__list-depth0 .slide-toggle__trigger {
	margin-top: 0.5em;

	&:first-child {
		margin-top: 0;
	}
}
