:root {
    --field-default-border: 1px solid #{$color-grey-02};
    --field-default-background: #{$color-white};

    --field-padding-vertical: 0.75em;
    --field-padding-horizontal: 0.88888em;

    --field-padding-vertical-sm: 0.4em;
    --field-padding-horizontal-sm: 0.8em;

    --field-padding-vertical-xsm: 0.3em;
    --field-padding-horizontal-xsm: 0.75em;
}

@mixin form-textbox-styles {
    border: var(--field-default-border);
    background-color: var(--field-default-background);
    font-family: $primary-font;
}

@mixin form-textbox($size: 'default') {
    display: block;
    width: 100%;
    outline: none;
    line-height: normal;
    -webkit-appearance: none;
    line-height: var(--body-line-height);

    @if $size == 'default' {
        padding: var(--field-padding-vertical) var(--field-padding-horizontal);
    }

    @if $size == 'small' {
        font-size: var(--centi-font-size);
        padding: var(--field-padding-vertical-sm) var(--field-padding-horizontal-sm);
    }

    @if $size == 'xsmall' {
        font-size: var(--milli-font-size);
        padding: var(--field-padding-vertical-xsm) var(--field-padding-horizontal-xsm);
    }
}

@mixin form-select($size: 'default') {
    @include form-textbox;
    @include form-textbox-styles;
    -moz-appearance: none;
    background-image:
        url(#{$file-path}icon-system/icon_form_arrow-down.svg),
        url(#{$file-path}icon-system/form_bg-fill.svg);
    background-repeat: no-repeat, repeat-Y;
    background-position: center right $form-spacing, center right;
    background-size: $form-spacing*3, $form-spacing*5;
    padding-right: $form-spacing*6.5;
}

@mixin field-label {
    font-weight: $fw-bold;
    margin-bottom: $form-spacing;
    line-height: normal;
    font-family: $primary-font;
}

@mixin field-message {
    margin: 0 0 $spacing;
    padding: $spacing $spacing*2;
    text-align: left;

    a {
        color: inherit;
    }
}

// Fieldsets
@mixin fieldsetLabel {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

@mixin fieldsetInput($small: false) {
    display: inline-block;
    position: relative;
    border-radius: $standard-radius;
    @include form-textbox-styles;

    @if $small {
        width: var(--input-small-diameter);
        height: var(--input-small-diameter);
    }
}

@mixin fieldsetInputCheckboxChecked($small: false) {
    @include fieldsetInput($small);
    background-image: url(../assets/icon-system/icon_form_tick-white.svg);
    background-color: $color-utility-neutral;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 90%;

    @if $small {
        background-image: url(../assets/icon-system/icon_form_tick-thick-white.svg);
    }
}

@mixin fieldsetInputRadioChecked($small: false) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--input-small-diameter) - 1em);
    height: calc(var(--input-small-diameter) - 1em);
    border-radius: 100%;
    background: $color-white;

    @if $small {
        width: calc(var(--input-small-diameter) - 0.75em);
        height: calc(var(--input-small-diameter) - 0.75em);
    }
}
