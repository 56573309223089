:root {
    --button-border-width: 3px;
    --button-border-radius: 0;
}

@mixin button-base() {
    text-align: center;
    vertical-align: middle;
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 0.5em;
    justify-content: center;
    align-items: center;
    white-space: normal;
    cursor: pointer;
    border-radius: var(--button-border-radius);
    outline: none;
    opacity: 1;
    position: relative;
    margin: 0;
    text-decoration: none;
    @include button-width-regular;
    @include button-height-regular;
    @include button-typography;
}

@mixin button-typography() {
    text-transform: uppercase;
    font-weight: bold;
    font-family: $accent-font;
    line-height: normal;
}

@mixin button($background: $color-primary, $colour: $color-white) {
    @include button-base;
    color: $colour;
    background: $background;
    border: var(--button-border-width) solid $background;
    transition:
        background-color $link-transition,
        border-color $link-transition,
        color $link-transition;

    svg {
        height: 1em;
        width: 1em;

        @include svg-contents {
            fill: $colour;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($background, 10%);
        border-color: darken($background, 10%);
        color: $colour;

        svg {
            @include svg-contents {
                fill: $colour;
            }
        }
    }
}

/* --------------------------------
Text Link
----------------------------------- */

@mixin text-link($colour: $color-accent-01, $padding: false) {
    @include button-typography;
    color: $colour;
    background: transparent;
    transition:
        transform $link-transition,
        background-color $link-transition,
        color $link-transition;

    @if $padding {
        @include button-height-regular;
    }

    &:after {
        content: " >";
    }

    svg {
        height: 1em;
        width: 1em;

        @include svg-contents {
            fill: $colour;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $colour;

        svg {
            @include svg-contents {
                fill: $colour;
            }
        }
    }
}


/* --------------------------------
Sizes
----------------------------------- */

/* Width - Inline Padding  */
@mixin button-width-small() {
    padding-left: 1em;
    padding-right: 1em;
}

@mixin button-width-regular() {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

@mixin button-width-large() {
    padding-left: 2em;
    padding-right: 2em;
}

/* Height - Block Padding */
@mixin button-height-small() {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

@mixin button-height-regular() {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

@mixin button-height-large() {
    padding-top: 1em;
    padding-bottom: 1em;
}

/* Text Size */
@mixin button-text-small() {
    font-size: 80%;
}

@mixin button-text-regular() {
    font-size: $fs-body;
}

@mixin button-text-large() {
    font-size: 150%;
}
