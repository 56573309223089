
:root {
    --body-font-size: 1.6rem;

    // headings
    --h1-font-size: 4rem;
    --h1-line-height: 1.2;

    --h2-font-size: 3.2rem;
    --h2-line-height: 1.5;

    --h3-font-size: 2.4rem;
    --h3-line-height: inherit;

    --h4-font-size: 2.2rem;
    --h4-line-height: 1.5;

    --h5-font-size: 2rem;
    --h5-line-height: inherit;

    --h6-font-size: 1.6rem;
    --h6-line-height: inherit;

    /* [02] */
    // big
    --giga-font-size: 4rem;
    --mega-font-size: 3.2rem;
    --kilo-font-size: 2.8rem;

    // small
    --milli-font-size: 1.2rem;
    --centi-font-size: 1.4rem;
}

@media screen and (min-width: 48em) { /* 768px */

    :root {
        --body-font-size: 1.6rem;

        // headings
        --h1-font-size: 4rem;
        --h1-line-height: 1.2;

        --h2-font-size: 3.2rem;
        --h2-line-height: 1.5;

        --h3-font-size: 2.4rem;
        --h3-line-height: inherit;

        --h4-font-size: 2.2rem;
        --h4-line-height: 1.5;

        --h5-font-size: 2rem;
        --h5-line-height: inherit;

        --h6-font-size: 1.6rem;
        --h6-line-height: inherit;

        /* [02] */
        // big
        --giga-font-size: 7.2rem;
        --mega-font-size: 6.4rem;
        --kilo-font-size: 5.6rem;

        // small
        --milli-font-size: 1.2rem;
        --centi-font-size: 1.4rem;
    }
}

/*
Basic system font stacks
*/

$primary-font: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

$accent-font: 'Roboto Condensed', Arial Narrow, sans-serif;


/* Weights */

$fw-normal: 400;

$fw-bold: 700;


/* Size */

$fs-small: var(--milli-font-size);

$fs-body: var(--body-font-size);



/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
