
.home-banner-layout {
    display: grid;

    @include screen($bp1200) {
        grid-template-columns: 2fr 1fr;
    }

    @include screen($bp1400) {
        grid-template-columns: auto 50ch;
        margin-right: var(--default-container-gutter);
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: var(--default-container-gutter);
            background-color: $color-accent-02;

        }
    }
}

.home-banner {

    &__news {
        background-color: $color-accent-02;
        display: grid;
        gap: $spacing*2;
        padding: $spacing--xlarge;
        padding-left:  var(--default-container-gutter);
        padding-right:  var(--default-container-gutter);

        @include screen($bp1200) {
            @include box;
        }

        @include screen($bp1400) {
            padding-right: 0;
        }


    }
}

.news {
    border-top: 1px solid $color-primary;
    padding-top: $spacing*2;
    display: grid;
    align-content: center;

    @include screen($bp1200) {
        margin-right: $spacing*2;
        max-width: 52ch;
    }

    @include screen($bp1400) {
        margin-right: 0;
    }

    &:first-child {
        padding-top: 0;
        border-top: 0;
    }

    &__heading {
        line-height: 1.25;
        margin-top: 0;
        margin-bottom: $spacing;
    }

    &__content {

        &__excerpt {

            &--has-link {
                display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}
