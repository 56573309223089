@charset "UTF-8";
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.6rem;
  --h1-font-size: 4rem;
  --h1-line-height: 1.2;
  --h2-font-size: 3.2rem;
  --h2-line-height: 1.5;
  --h3-font-size: 2.4rem;
  --h3-line-height: inherit;
  --h4-font-size: 2.2rem;
  --h4-line-height: 1.5;
  --h5-font-size: 2rem;
  --h5-line-height: inherit;
  --h6-font-size: 1.6rem;
  --h6-line-height: inherit;
  /* [02] */
  --giga-font-size: 4rem;
  --mega-font-size: 3.2rem;
  --kilo-font-size: 2.8rem;
  --milli-font-size: 1.2rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 48em) {
  /* 768px */
  :root {
    --body-font-size: 1.6rem;
    --h1-font-size: 4rem;
    --h1-line-height: 1.2;
    --h2-font-size: 3.2rem;
    --h2-line-height: 1.5;
    --h3-font-size: 2.4rem;
    --h3-line-height: inherit;
    --h4-font-size: 2.2rem;
    --h4-line-height: 1.5;
    --h5-font-size: 2rem;
    --h5-line-height: inherit;
    --h6-font-size: 1.6rem;
    --h6-line-height: inherit;
    /* [02] */
    --giga-font-size: 7.2rem;
    --mega-font-size: 6.4rem;
    --kilo-font-size: 5.6rem;
    --milli-font-size: 1.2rem;
    --centi-font-size: 1.4rem;
  }
}
/*
Basic system font stacks
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/* ---- Mixins ---- */
:root {
  --button-border-width: 3px;
  --button-border-radius: 0;
}

/* --------------------------------
Text Link
----------------------------------- */
/* --------------------------------
Sizes
----------------------------------- */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
List Mixins
------------------------------*/
/* -----------------------------
Fallback logo image
------------------------------*/
:root {
  --field-default-border: 1px solid #d4d4d4;
  --field-default-background: #ffffff;
  --field-padding-vertical: 0.75em;
  --field-padding-horizontal: 0.88888em;
  --field-padding-vertical-sm: 0.4em;
  --field-padding-horizontal-sm: 0.8em;
  --field-padding-vertical-xsm: 0.3em;
  --field-padding-horizontal-xsm: 0.75em;
}

/* ---- Abstracts ---- */
/* Fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ------------------------------------------------------
**** Blocks
------------------------------------------------------ */
.hero-banner {
  display: flex;
  flex-flow: column nowrap;
}
.hero-banner__img {
  flex: 1 0 auto;
}
.hero-banner__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.hero-banner__text {
  background-color: #2D96D3;
  color: #ffffff;
  padding: 32px;
  padding-left: var(--default-container-gutter);
  padding-right: var(--default-container-gutter);
}
@media screen and (min-width: 75em) {
  .hero-banner__text {
    padding: 16px;
  }
  .hero-banner__text > *:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) and (min-width: 22.5em) {
  .hero-banner__text {
    padding: 24px;
  }
}
@media screen and (min-width: 75em) and (min-width: 48em) {
  .hero-banner__text {
    padding: 32px;
  }
}
@media screen and (min-width: 87.5em) {
  .hero-banner__text {
    padding-left: var(--default-container-gutter);
  }
}
.hero-banner__text__title {
  color: #ffffff;
}

.inner-banner {
  min-height: 25vh;
  position: relative;
  display: grid;
}
.inner-banner__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.inner-banner__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.inner-banner__img:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.inner-banner__content {
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}
.inner-banner__content a {
  color: currentColor;
}
.inner-banner__content a:hover, .inner-banner__content a:focus, .inner-banner__content a:active {
  color: #2D96D3;
}
.inner-banner__content .page-header__heading {
  margin-top: 8px;
}
.inner-banner__content .breadcrumb {
  margin-top: 16px;
  color: #ffffff;
}
.inner-banner__content .breadcrumb a {
  color: rgba(255, 255, 255, 0.8);
}
.inner-banner__content .breadcrumb a:hover, .inner-banner__content .breadcrumb a:focus {
  color: #ffffff;
}

.contact-details__list {
  font-style: normal;
}
.contact-details__item--icon {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
}
.contact-details__item--icon a {
  text-decoration-color: rgba(255, 255, 255, 0);
  transition: all 250ms ease-in-out;
}
.contact-details__item--icon a:hover, .contact-details__item--icon a:focus, .contact-details__item--icon a:active {
  color: #2D96D3;
  text-decoration-color: #2d96d3;
}
.contact-details strong {
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
}

.opening-hours__title {
  display: block;
}
.opening-hours__hours {
  display: block;
}

.page-header {
  text-align: left;
  margin-top: var(--section-small);
  margin-bottom: var(--section-small);
}
.page-header__subtitle {
  font-size: var(--body-font-size);
}
.page-header__title {
  display: block;
  line-height: 1;
  font-size: var(--giga-font-size);
  margin-bottom: 0;
}

.image-gallery__figure {
  display: block;
  overflow: hidden;
  margin: 0;
}

.image-gallery__slider .splide__slide img {
  user-select: none;
  transition: opacity 1s, transform 1s;
}
.image-gallery__slider .splide__slide img:hover, .image-gallery__slider .splide__slide img:focus, .image-gallery__slider .splide__slide img:active {
  opacity: 0.9;
  transform: scale3d(1.1, 1.1, 1);
}
.image-gallery__slider .splide__slide.active img {
  animation: fadeIn 350ms ease-in;
  animation-fill-mode: forwards;
}
.image-gallery__slider.columns-2 .splide__slide {
  flex: 0 0 50%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-3 .splide__slide {
  flex: 0 0 33.333%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-4 .splide__slide {
  flex: 0 0 25%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-5 .splide__slide {
  flex: 0 0 20%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-6 .splide__slide {
  flex: 0 0 16.666%;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.image-gallery__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.image-gallery__grid.columns-2, .image-gallery__grid.columns-4 {
  grid-template-columns: repeat(2, 1fr);
}
.image-gallery__grid.columns-3, .image-gallery__grid.columns-5, .image-gallery__grid.columns-6 {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.image-gallery__grid figure,
.image-gallery__grid img {
  width: 100%;
}
.image-gallery__grid .image-gallery__figure {
  position: relative;
}
.image-gallery__grid .image-gallery__figure:hover figcaption, .image-gallery__grid .image-gallery__figure:focus figcaption, .image-gallery__grid .image-gallery__figure:active figcaption {
  opacity: 1;
}
.image-gallery__grid .image-gallery__figure figcaption {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  z-index: 2;
  opacity: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  margin: 0;
  transition: 0.4s all ease;
  line-height: 1.3;
}
@media screen and (min-width: 35em) {
  .image-gallery__grid .image-gallery__figure figcaption {
    font-size: 18px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    padding: 16.6666%;
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid .image-gallery__figure figcaption {
    font-size: 24px;
  }
}

:root {
  --sidepadding: 8px;
  --vertpadding: 8px;
}

@media screen and (min-width: 22.5em) {
  :root {
    --sidepadding: 12px;
    --vertpadding: 10px;
  }
}
@media screen and (min-width: 35em) {
  :root {
    --sidepadding: 16px;
    --vertpadding: 12px;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --sidepadding: 24px;
    --vertpadding: 16px;
  }
}
@media screen and (min-width: 87.5em) {
  :root {
    --sidepadding: 44px;
    --vertpadding: 28px;
  }
}
.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object {
  padding: 20px;
  width: 100%;
  float: left;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__details {
  font-weight: bold;
  color: #7f7f7f;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
}
.card {
  background: #fff;
  color: #222;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card a:focus {
  text-decoration: underline;
}

.card:focus-within {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card:focus-within a:focus {
  text-decoration: none;
}

.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
}

.card__content > * + * {
  margin-top: 8px;
}

.card__content :last-child {
  margin-top: auto;
}

.card__content :nth-last-child(2) {
  margin-bottom: 8px;
}

.card__description {
  margin-bottom: auto;
  padding-top: 30px;
}

.card__content,
.card__description,
.card__hover,
.card__cta {
  padding-left: 15px;
  padding-right: 15px;
}

.card__content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.card__cta {
  padding-bottom: 15px;
}

.card__img {
  height: 200px;
}

.card__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* Hover variation */
.card--hover {
  position: relative;
}

.card--hover .card__content {
  padding: 0;
}

.card--hover .card__content :last-child {
  margin-top: 0;
}

.card--hover .card__content :last-child {
  margin-top: 0;
}

.card__hover {
  display: none;
}

.card__cta {
  order: 2;
}

.card__cta p {
  margin-bottom: 0;
}

.card__img {
  order: 0;
}

.card__description {
  order: 1;
}

@media screen and (min-width: 48em) {
  .card__content,
.card__description,
.card__hover,
.card__cta {
    padding-left: 30px;
    padding-right: 30px;
  }

  .card__content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .card__cta {
    padding-bottom: 30px;
  }

  .card--hover .card__content {
    padding: 0;
  }
}
@media screen and (min-width: 75em) {
  .card__cta {
    width: 100%;
  }

  .card__hover {
    display: block;
    overflow: hidden;
    padding-top: 0;
    padding-left: 30px;
    padding-right: 15px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    transition: height 300ms cubic-bezier(0.38, -0.14, 0.51, 0.99), padding-top 300ms cubic-bezier(0.38, -0.14, 0.51, 0.99);
  }

  .card:hover .card__hover {
    height: 371px;
    padding-top: 30px;
  }
}
.tile {
  background: #ffffff;
}

.tile__inner {
  height: 100%;
}

.tile__img img {
  width: 100%;
}

.tile__content {
  padding: 20px 25px;
}

.splide__arrows {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 3;
}
.splide__arrow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: background 250ms ease-in-out;
  display: grid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.splide__arrow svg * {
  fill: #ffffff;
}
.splide__arrow:hover, .splide__arrow:focus, .splide__arrow:active {
  background-color: rgba(0, 0, 0, 0.75);
}
.splide__arrow--prev {
  left: 0;
  transform: rotate(180deg);
}
.splide__arrow--next {
  right: 0;
}
.splide__pagination {
  gap: 16px;
}
.splide__pagination__page {
  cursor: pointer;
  background-color: #b8b8b8;
  border: 0;
  border-radius: 200px;
  width: 12px;
  height: 12px;
  transition: background 250ms ease-in-out;
}
.splide__pagination__page.is-active {
  background-color: #023578;
}
.splide__pagination__page:hover, .splide__pagination__page:focus, .splide__pagination__page:active {
  background-color: #636363;
}

.cta-box {
  background-color: #F0F0F0;
  background-image: url(../assets/deco-white-grid.svg);
  background-repeat: repeat-y;
  padding: 16px 16px 16px 32px;
}
.cta-box > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .cta-box {
    padding: 24px 24px 24px 40px;
  }
}
@media screen and (min-width: 48em) {
  .cta-box {
    padding: 32px 32px 32px 48px;
  }
}
.cta-box__heading {
  margin-top: 0;
  line-height: normal;
}
.cta-box__heading span {
  display: block;
}
.cta-box__subtitle {
  display: block;
  font-size: var(--centi-font-size);
  color: #7f7f7f;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: var(--body-font-size);
}
.cta-box__title {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: inherit;
}
.cta-box__cta a {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #023578;
  border: var(--button-border-width) solid #023578;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.cta-box__cta a svg {
  height: 1em;
  width: 1em;
}
.cta-box__cta a svg path,
.cta-box__cta a svg circle,
.cta-box__cta a svg ellipse,
.cta-box__cta a svg rect,
.cta-box__cta a svg line,
.cta-box__cta a svg polygon {
  fill: #ffffff;
}
.cta-box__cta a:hover, .cta-box__cta a:focus, .cta-box__cta a:active {
  background-color: #011f46;
  border-color: #011f46;
  color: #ffffff;
}
.cta-box__cta a:hover svg path,
.cta-box__cta a:hover svg circle,
.cta-box__cta a:hover svg ellipse,
.cta-box__cta a:hover svg rect,
.cta-box__cta a:hover svg line,
.cta-box__cta a:hover svg polygon, .cta-box__cta a:focus svg path,
.cta-box__cta a:focus svg circle,
.cta-box__cta a:focus svg ellipse,
.cta-box__cta a:focus svg rect,
.cta-box__cta a:focus svg line,
.cta-box__cta a:focus svg polygon, .cta-box__cta a:active svg path,
.cta-box__cta a:active svg circle,
.cta-box__cta a:active svg ellipse,
.cta-box__cta a:active svg rect,
.cta-box__cta a:active svg line,
.cta-box__cta a:active svg polygon {
  fill: #ffffff;
}
.cta-box__content > *:last-child {
  margin-bottom: 0;
}

/* ---- Slide Toggle ---- */
.slide-toggle__trigger {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  position: relative;
  white-space: normal;
  vertical-align: middle;
  outline: none;
  border: none;
  transition: all 250ms ease-in-out;
  background-color: #F0F0F0;
  background-color: transparent;
  padding: 8px 0;
  margin: 0;
  text-align: left;
  color: #7f7f7f;
}
.slide-toggle__trigger:hover, .slide-toggle__trigger:focus {
  color: #0f0f0f;
}
.slide-toggle__trigger:hover:before, .slide-toggle__trigger:hover:after, .slide-toggle__trigger:focus:before, .slide-toggle__trigger:focus:after {
  background-color: #0f0f0f;
}
.slide-toggle__trigger:before, .slide-toggle__trigger:after {
  content: " ";
  width: 12px;
  height: 3px;
  display: block;
  position: absolute;
  background-color: #7f7f7f;
  margin: 0 auto;
  right: 20px;
  top: 50%;
  transition: all 400ms ease;
}
.slide-toggle__trigger:before {
  transform: rotate(45deg) translateX(-5px);
}
.slide-toggle__trigger:after {
  transform: rotate(-45deg) translateX(5px);
}
.slide-toggle__trigger[aria-pressed=true] {
  color: #2D96D3;
}
.slide-toggle__trigger[aria-pressed=true]:before, .slide-toggle__trigger[aria-pressed=true]:after {
  background-color: #2D96D3;
  top: calc(50% - 2px);
}
.slide-toggle__trigger[aria-pressed=true]:hover, .slide-toggle__trigger[aria-pressed=true]:focus {
  color: #0f0f0f;
}
.slide-toggle__trigger[aria-pressed=true]:hover:before, .slide-toggle__trigger[aria-pressed=true]:hover:after, .slide-toggle__trigger[aria-pressed=true]:focus:before, .slide-toggle__trigger[aria-pressed=true]:focus:after {
  background-color: #0f0f0f;
}
.slide-toggle__trigger[aria-pressed=true]:before {
  transform: rotate(-45deg) translateX(-5px);
}
.slide-toggle__trigger[aria-pressed=true]:after {
  transform: rotate(45deg) translateX(5px);
}

.archive-links__list[aria-expanded=false] {
  display: none;
}

.archive-links__list[aria-expanded=true] {
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}

.archive-links__list-depth0 .slide-toggle__trigger {
  margin-top: 0.5em;
}
.archive-links__list-depth0 .slide-toggle__trigger:first-child {
  margin-top: 0;
}

@media screen and (min-width: 62em) {
  .tab-exp-nav__list__btn {
    background-color: #F0F0F0;
    border: 2px solid #d4d4d4;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    line-height: 1.125;
    min-height: 48px;
    padding: 8px 16px;
  }
  .tab-exp-nav__list__btn[aria-selected=true] {
    background-color: #2D96D3;
    color: #ffffff;
  }
}
.tab-exp-nav__panel {
  position: relative;
  padding-top: 16px;
}
@media screen and (max-width: 61.9375em) {
  .tab-exp-nav__panel__contents {
    overflow: hidden;
    transition: height 250ms ease-in-out;
  }
}
.tab-exp-nav__panel__contents.tabpanel--closed {
  height: 10ch !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tab-exp-nav__panel__contents__heading {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 62em) {
  .tab-exp-nav__panel__contents__heading {
    display: none;
  }
}
.tab-exp-nav__panel__more-btn {
  background-color: #2D96D3;
  color: #ffffff;
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  cursor: pointer;
  max-width: 200px;
  margin: 8px 0 0 0;
  height: 40px;
  opacity: 1;
  overflow: hidden;
  transition: all 250ms ease-in-out;
  clip: unset;
}
.tab-exp-nav__panel__more-btn--hide {
  font-size: 0;
  height: 0px;
  border: 0px;
  padding-top: 0;
  padding-bottom: 0;
  clip: rect(0 0 0 0);
  margin: 0px;
  display: grid;
  opacity: 0;
}
@media screen and (min-width: 62em) {
  .tab-exp-nav__panel__more-btn {
    display: none;
  }
}

.content-block {
  display: grid;
  gap: 4.8888888vw;
}
@media screen and (min-width: 48em) {
  .content-block {
    gap: 5.11111vw;
  }
}
@media screen and (min-width: 62em) {
  .content-block {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 100em) {
  .content-block {
    column-gap: 80px;
    row-gap: 40px;
  }
}
@media screen and (min-width: 62em) {
  .content-block.half-media-right {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.half-media-left {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.third-media-right {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.third-media-left {
    grid-template-columns: 1fr 2fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.third-copy-right {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.third-copy-left {
    grid-template-columns: 1fr 2fr;
  }
}

.entry-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}
.entry-list.full-width {
  grid-template-columns: 1fr;
}
@media screen and (min-width: 48em) {
  .entry-list.large {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
@media screen and (min-width: 62em) {
  .entry-list.large {
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  }
}
@media screen and (min-width: 48em) {
  .entry-list.medium {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
@media screen and (min-width: 62em) {
  .entry-list.medium {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
@media screen and (min-width: 35em) {
  .entry-list.small {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
.entry-list.extra-small {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}
.entry-list.tiny {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
.mobile-header__logo-link {
  max-width: 180px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: grid;
  align-items: center;
}
.mobile-header__logo-link svg {
  max-width: 100%;
  height: auto;
}

#header {
  min-height: 120px;
  z-index: 100;
  display: none;
  transition: height 350ms ease;
}
@media screen and (min-width: 62em) {
  #header {
    display: block;
  }
}

.header {
  height: 100%;
  background-color: #ffffff;
  padding-top: 16px;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 150em) {
  .header {
    max-width: 2400px;
    margin: 0 auto;
  }
}
.header__nav {
  background: #023578;
  border-bottom: 4px solid #EB8A01;
}
.header__logo {
  display: block;
  transition: height 350ms ease;
}
@media screen and (max-width: 61.9375em) {
  .header__logo {
    height: calc(48px * 0.8);
  }
}
@media screen and (min-width: 62em) {
  .header__logo {
    height: 120px;
  }
}
.header__logo svg {
  height: 100%;
}
.header__login {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #2D96D3;
  border: var(--button-border-width) solid #2D96D3;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.header__login svg {
  height: 1em;
  width: 1em;
}
.header__login svg path,
.header__login svg circle,
.header__login svg ellipse,
.header__login svg rect,
.header__login svg line,
.header__login svg polygon {
  fill: #ffffff;
}
.header__login:hover, .header__login:focus, .header__login:active {
  background-color: #2478a9;
  border-color: #2478a9;
  color: #ffffff;
}
.header__login:hover svg path,
.header__login:hover svg circle,
.header__login:hover svg ellipse,
.header__login:hover svg rect,
.header__login:hover svg line,
.header__login:hover svg polygon, .header__login:focus svg path,
.header__login:focus svg circle,
.header__login:focus svg ellipse,
.header__login:focus svg rect,
.header__login:focus svg line,
.header__login:focus svg polygon, .header__login:active svg path,
.header__login:active svg circle,
.header__login:active svg ellipse,
.header__login:active svg rect,
.header__login:active svg line,
.header__login:active svg polygon {
  fill: #ffffff;
}

.section--pre-footer {
  background: #023578 url(../assets/deco-white-grid.svg) repeat-x;
  color: #ffffff;
}
.section--pre-footer a {
  color: currentColor;
}
.section--pre-footer a:hover, .section--pre-footer a:focus, .section--pre-footer a:active {
  color: #6cb6e0;
}

.footer-heading {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.section--footer {
  background: #2D96D3;
  color: #ffffff;
  padding: 32px 0;
  text-align: center;
}
.section--footer a {
  color: currentColor;
}
.section--footer a:hover, .section--footer a:focus, .section--footer a:active {
  color: #2D96D3;
}

.footer {
  display: grid;
  gap: 1.6rem;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .footer {
    justify-content: center;
  }
}
@media screen and (min-width: 87.5em) {
  .footer {
    justify-content: space-between;
    grid-template-columns: auto auto;
  }
}
.footer__linklist {
  list-style-type: none;
  padding-left: 0;
  display: inline-grid;
  gap: 8px;
}
.footer__linklist li a {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  font-size: 2rem;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  transition: color 250ms ease-in-out;
}
.footer__linklist li a svg * {
  transition: fill 250ms ease-in-out;
  fill: currentColor;
}
.footer__text-wrap__text {
  padding-top: 16px;
  padding-bottom: 0;
  display: grid;
  gap: 24px;
}
@media screen and (min-width: 87.5em) {
  .footer__text-wrap__text {
    font-size: 1.5rem;
    grid-auto-flow: column;
    justify-content: flex-start;
  }
}
.footer__text-wrap__text p {
  margin: 0;
  display: inline;
}
.footer__text-wrap__tech-links {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(32%, auto));
}
@media screen and (min-width: 48em) {
  .footer__text-wrap__tech-links {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }
}
.footer__text-wrap__tech-links a {
  text-decoration-color: rgba(255, 255, 255, 0);
  transition: all 250ms ease-in-out;
}
.footer__text-wrap__tech-links a:hover, .footer__text-wrap__tech-links a:focus, .footer__text-wrap__tech-links a:active {
  color: currentColor;
  text-decoration-color: white;
}
.footer-cols {
  display: grid;
  gap: 4.8888888vw;
}
@media screen and (min-width: 48em) {
  .footer-cols {
    gap: 5.11111vw;
  }
}
@media screen and (min-width: 62em) {
  .footer-cols {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 100em) {
  .footer-cols {
    gap: 80px;
  }
}

.footer-logos {
  list-style-type: none;
  padding-left: 0;
  margin: 8px 0 0 0;
  display: grid;
  gap: 32px;
  align-items: end;
  grid-template-columns: auto auto auto;
}
@media screen and (min-width: 48em) {
  .footer-logos {
    margin-top: 0;
    grid-auto-flow: column;
  }
}
@media screen and (min-width: 48em) {
  .footer-logos__item--gosa {
    margin-bottom: -10px;
  }
}

.inner-scaffold {
  display: grid;
  gap: 4.8888888vw;
}
@media screen and (min-width: 48em) {
  .inner-scaffold {
    gap: 5.11111vw;
  }
}
@media screen and (min-width: 62em) {
  .inner-scaffold {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 100em) {
  .inner-scaffold {
    gap: 80px;
  }
}

.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tile-list:before, .tile-list:after {
  content: " ";
  display: table;
}
.tile-list:after {
  clear: both;
}
.tile-list__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /*  768px */
  .tile-list {
    margin: 0 -30px;
  }
  .tile-list__item {
    width: 33.333%;
    padding: 15px 30px;
    float: left;
  }
}
/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 87.5em) {
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 61.9375em) {
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object__list:before, .media-object__list:after {
  content: " ";
  display: table;
}
.media-object__list:after {
  clear: both;
}

.media-object__item {
  padding: 10px 0;
}

.media-object__list:after {
  clear: both;
}

.card__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

@supports (display: grid) {
  .card__list {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .card__list--lg {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  .card__item + .card__item {
    margin-top: 0;
  }
}
.sitemap {
  text-align: left;
}
@media screen and (min-width: 48em) {
  .sitemap {
    columns: 2;
  }
}
@media screen and (min-width: 62em) {
  .sitemap {
    columns: 3;
  }
}
.sitemap__section {
  break-inside: avoid;
}
.sitemap__section:first-child .sitemap__list-title {
  margin-top: 0;
}
.sitemap__list-title {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: inherit;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.sitemap__list-subtitle {
  font-weight: 700;
  margin-bottom: 0.25em;
  margin-top: 0.5em;
  opacity: 0.8;
}
.sitemap ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sitemap ul > li:first-child > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.sitemap ul > li:last-child > a {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.sitemap .sitemap-item button {
  display: block;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-align: left;
  padding-left: 1.25em;
}
.sitemap .sitemap-item a,
.sitemap .sitemap-item button {
  text-decoration: none;
  display: block;
  line-height: 2;
  background-color: transparent;
  color: #636363;
  border: 1px solid #F0F0F0;
  border-left: 3px solid #9c9c9c;
  margin-bottom: -1px;
  transition: color 250ms ease-in-out, border-color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.sitemap .sitemap-item a:hover, .sitemap .sitemap-item a:focus, .sitemap .sitemap-item a:active,
.sitemap .sitemap-item button:hover,
.sitemap .sitemap-item button:focus,
.sitemap .sitemap-item button:active {
  background-color: #F0F0F0;
  color: #023578;
  border-left: 3px solid #023578;
}
.sitemap .sitemap-item-depth1 a {
  padding-left: 1.25em;
}
.sitemap .sitemap-item-depth2 a {
  padding-left: 2.5em;
}
.sitemap .sitemap-item-depth3 a {
  padding-left: 3.75em;
}

@media screen and (min-width: 48em) {
  @supports (display: grid) {
    .media-article {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 2fr 3fr;
    }
  }
}
.twofa-verify-form {
  display: grid;
  width: 24ch;
  max-width: 100%;
}
.twofa-verify-form__code-input {
  min-height: 42px;
  padding: 8px 16px;
  text-align: center;
}
.twofa-verify-form .button {
  width: 100%;
}

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */
.home-banner-layout {
  display: grid;
}
@media screen and (min-width: 75em) {
  .home-banner-layout {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 87.5em) {
  .home-banner-layout {
    grid-template-columns: auto 50ch;
    margin-right: var(--default-container-gutter);
    position: relative;
  }
  .home-banner-layout::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: var(--default-container-gutter);
    background-color: #dce3ec;
  }
}

.home-banner__news {
  background-color: #dce3ec;
  display: grid;
  gap: 16px;
  padding: 32px;
  padding-left: var(--default-container-gutter);
  padding-right: var(--default-container-gutter);
}
@media screen and (min-width: 75em) {
  .home-banner__news {
    padding: 16px;
  }
  .home-banner__news > *:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) and (min-width: 22.5em) {
  .home-banner__news {
    padding: 24px;
  }
}
@media screen and (min-width: 75em) and (min-width: 48em) {
  .home-banner__news {
    padding: 32px;
  }
}
@media screen and (min-width: 87.5em) {
  .home-banner__news {
    padding-right: 0;
  }
}

.news {
  border-top: 1px solid #023578;
  padding-top: 16px;
  display: grid;
  align-content: center;
}
@media screen and (min-width: 75em) {
  .news {
    margin-right: 16px;
    max-width: 52ch;
  }
}
@media screen and (min-width: 87.5em) {
  .news {
    margin-right: 0;
  }
}
.news:first-child {
  padding-top: 0;
  border-top: 0;
}
.news__heading {
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 8px;
}
.news__content__excerpt--has-link {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}