.contact-details {
    &__list {
        font-style: normal;
    }

    &__item--icon {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        gap: $spacing;

        a {
            text-decoration-color: rgba(255,255,255,0);
            transition: all $link-transition;

            &:hover,
            &:focus,
            &:active {
                color: $color-secondary;
                text-decoration-color: rgba($color-secondary,1);
            }

        }
    }

    strong {
        font-family: $accent-font;
    }
}

.opening-hours {
    &__title {
        display: block;
    }

    &__hours {
        display: block;
    }
}
