.mobile-header {

    &__logo-link {
        max-width: 180px;
        padding-top: $spacing;
        padding-bottom: $spacing;
        display: grid;
        align-items: center;

        svg {
            max-width: 100%;
            height: auto;
        }
    }
}

#header {
    min-height: $header-height;
    z-index: $header-index;
    display: none;
    transition:
        height $header-transition;

    @include screen($bpdesktop) {
        display: block;
    }
}

.header {
    height: 100%;
    background-color: $color-white;
    padding-top: $spacing*2;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include screen($bp2400) {
        max-width: $max-page-width;
        margin: 0 auto;
    }

    &__nav {
        background: $color-primary;
        border-bottom: $spacing*.5 solid $color-accent-01;
    }

    &__logo {
        display: block;
        transition:
            height $header-transition;

        @include screen($bpmobile, 'max') {
            height: calc(#{$header-height-mobile} * 0.8);
        }

        @include screen($bpdesktop) {
            height: $header-height;
        }

        svg {
            height: 100%;
        }
    }

    &__login {
        @include button($color-secondary);
    }
}


