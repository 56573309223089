
.card {
    background:#fff;
    color:#222;
    padding:0;
    display:flex;
    flex-direction:column;
    height: 100%;
}

.card:hover {
    box-shadow:0 0 2px 0 rgba(0, 0, 0, 0.33)
}

.card a:focus {
    text-decoration:underline
}

.card:focus-within {
    box-shadow:0 0 2px 0 rgba(0, 0, 0, 0.33)
}

.card:focus-within a:focus {
    text-decoration:none
}

.card__content {
    display:flex;
    flex-direction:column;
    flex-grow:1;
    order:1
}

.card__content>*+* {
    margin-top:8px
}

.card__content :last-child {
    margin-top:auto
}

.card__content :nth-last-child(2) {
    margin-bottom:8px
}

.card__description {
    margin-bottom: auto;
    padding-top: 30px;
}

.card__content,
.card__description,
.card__hover,
.card__cta {
    padding-left: 15px;
    padding-right: 15px;
}

.card__content {
    padding-top: 15px;
    padding-bottom: 15px;
}

.card__cta {
    padding-bottom: 15px;
}

.card__img {
    height:200px
}

.card__img img {
    object-fit:cover;
    height:100%;
    width:100%
}

/* Hover variation */
.card--hover {
    position: relative;
}

.card--hover .card__content {
    padding: 0;
}

.card--hover .card__content :last-child {
    margin-top:0;
}

.card--hover .card__content :last-child {
    margin-top:0;
}

.card__hover {
    display: none;
}

.card__cta {
    order:2
}

.card__cta p {
    margin-bottom: 0;
}

.card__img {
    order: 0;
}

.card__description {
    order: 1;
}

@include screen($bp768) {
    .card__content,
    .card__description,
    .card__hover,
    .card__cta {
        padding-left: 30px;
        padding-right: 30px;
    }


    .card__content {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .card__cta {
        padding-bottom: 30px;
    }

    .card--hover .card__content {
        padding: 0;
    }
}

@include screen($bp1200) {
    .card__cta {
        width: 100%;
    }

    .card__hover {
        display: block;
        overflow: hidden;
        padding-top: 0;
        padding-left: 30px;
        padding-right: 15px;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        transition:
            height 300ms cubic-bezier(.38,-0.14,.51,.99),
            padding-top 300ms cubic-bezier(.38,-0.14,.51,.99);
    }

    .card:hover .card__hover {
        height: 371px;
        padding-top: 30px;
    }
}
