@mixin reverse-text {
    color: $color-white;

    & a {
        color: currentColor;
    }

    & a:hover,
    & a:focus,
    & a:active {
        color: $color-secondary;
    }
}

@mixin default-text {
    color: $color-body-font;

    & a {
        color: var(--text-link-colour);
    }

    & a:hover,
    & a:focus,
    & a:active {
        color: var(--text-link-hover-colour);
        opacity: 1;
    }
}
