/* -----------------------------
File Path
-------------------------------- */
$file-path: '../assets/';


/* -----------------------------
Spacing
-------------------------------- */
$spacing:             8px;

$spacing--xxsmall:            $spacing*.25;

$spacing--xsmall:             $spacing*.5;

$spacing--small:              $spacing;

$spacing--medium:             $spacing*2;

$spacing--large:              $spacing*3;

$spacing--xlarge:             $spacing*4;

$spacing--xxlarge:            $spacing*8;


/* -----------------------------
style variables
-------------------------------- */
$standard-radius:            4px; // note: button radius set manually

$form-spacing:            8px;


/* -----------------------------
Header
-------------------------------- */
$header-height-mobile: 48px;

$header-height: 120px;
// [03] this is referenced within the shrink header js code, update it there.

// $header-height-shrunk: 80px;

$header-transition: 350ms ease;

$header-index: 100;


/* -----------------------------
Page
-------------------------------- */
$max-page-width: 2400px;
