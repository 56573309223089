
.section--pre-footer {
    background: $color-primary url(../assets/deco-white-grid.svg) repeat-x;
    color: $color-white;

    a {
        color: currentColor;

        &:hover,
        &:focus,
        &:active {
            color: lighten($color-secondary, 15%);

        }
    }
}

.footer-heading {
    font-size: 2.4rem;
    line-height: 3.2rem;
}

.section--footer {
    background: $color-secondary;
    @include reverse-text;
    padding: $spacing--xlarge 0;
    text-align: center;
}

.footer {
    display: grid;
    gap: 1.6rem;
    align-items: center;

    @include screen($bp768) {
        justify-content: center;
    }
    @include screen($bp1400) {
        justify-content: space-between;
        grid-template-columns: auto auto;
    }


    &__linklist {
        @include list-reset;

        display: inline-grid;
        gap: $spacing;


        li {

            a {
                display: grid;
                grid-auto-flow: column;
                gap: $spacing*2;
                justify-content: start;
                align-items: center;
                text-decoration: none;
                font-size: 2rem;
                font-family: $accent-font;
                transition: color $link-transition;

                svg * {
                    transition: fill $link-transition;
                    fill: currentColor;
                }

            }
        }
    }

    &__text-wrap {

        &__text {
            padding-top: $spacing--medium;
            padding-bottom: 0;
            display: grid;
            gap: $spacing*3;

            @include screen($bp1400) {
                font-size: 1.5rem;
                grid-auto-flow: column;
                justify-content: flex-start;
            }

            p {
                margin: 0;
                display: inline;
            }
        }

        &__tech-links {
            @include list-reset;
            margin: 0;
            display: grid;
            gap: $spacing*2;
            grid-template-columns: repeat(auto-fit, minmax(32%, auto));

            @include screen($bp768) {
                grid-auto-flow: column;
                grid-template-columns: unset;
            }

            a {
                text-decoration-color: rgba($color-white, 0);
                transition: all $link-transition;

                &:hover,
                &:focus,
                &:active {
                    color: currentColor;
                    text-decoration-color: rgba($color-white, 1);
                }
            }
        }
    }

    &__logos {


    }
}

.footer-cols {
    display: grid;
    gap: 4.8888888vw;

    @include screen($bp768) {
        gap: 5.11111vw;
    }

    @include screen($bp992) {
        grid-template-columns: 2fr 1fr;
    }

    @include screen($bp1600) {
        gap: 80px;
    }

}

.footer-logos {
    @include list-reset;
    margin: $spacing 0 0 0;

    display: grid;
    gap: $spacing*4;
    align-items: end;
    grid-template-columns: auto auto auto;


    @include screen($bp768) {
        margin-top: 0;
        grid-auto-flow: column;
    }

    &__item {
        &--gosa {
            @include screen($bp768) {
                margin-bottom: -10px;
                // margin-bottom: -4.5%
            }

        }
    }
}
