/* -----------------------------
Visibility Utilities
------------------------------*/

@mixin vis-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin vis-hidden-reset() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}


/* -----------------------------
Clearfix
------------------------------*/
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}


/* -----------------------------
SVG
------------------------------*/
@mixin svg-contents() {
    path,
    circle,
    ellipse,
    rect,
    line,
    polygon {
        @content;
    }
}


/* -----------------------------
List Mixins
------------------------------*/
@mixin list-reset() {
    list-style-type: none;
    padding-left: 0;
}


/* -----------------------------
Fallback logo image
------------------------------*/
@mixin fallback-logo-image() {
    background: $color-grey-01;
    background-image: url(#{$file-path}logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}
