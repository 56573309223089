
.twofa-verify-form {
    display: grid;
    width: 24ch;
    max-width: 100%;

    &__code-input {
        min-height: 42px;
        padding: $spacing $spacing*2;
        text-align: center;
    }
    .button {
        width: 100%;
    }
}
