@mixin box($size: 'regular') {
    padding: $spacing*2;

    > *:last-child {
        margin-bottom: 0;
    }

    @if $size == 'small' {
        @include screen($bp360) {
            padding: 24px;
        }
    }

    @if $size == 'regular' {
        @include screen($bp360) {
            padding: 24px;
        }

        @include screen($bp768) {
            padding: 32px;
        }
    }

    @if $size == 'large' {
        @include screen($bp360) {
            padding: 24px;
        }

        @include screen($bp768) {
            padding: 40px;
        }
    }
}

@mixin box-lined($colour: $color-primary, $accentBorder: 'left') {
    border: 1px solid $color-grey-01;
    border-radius: 3px;
    margin-bottom: $fs-body;

    @if $accentBorder == 'left' {
        border-left: 3px solid $colour;
    }

    @if $accentBorder == 'bottom' {
        border-bottom: 3px solid $colour;
    }
}

@mixin box-with-grid-deco {
    background-image: url(../assets/deco-white-grid.svg);
    background-repeat: repeat-y;

    padding: $spacing*2 $spacing*2 $spacing*2 $spacing*4;

    > *:last-child {
        margin-bottom: 0;
    }

    @include screen($bp360) {
        padding: $spacing*3 $spacing*3 $spacing*3 $spacing*5;
    }

    @include screen($bp768) {
        padding: $spacing*4 $spacing*4 $spacing*4 $spacing*6;
    }
}
